@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.flex{
  display: flex;
}

.center{
  justify-content: center;
}


.home{
  display: flex;
  flex-direction: column;
}

.jumbotron-cont {
  height: auto;
  width: auto;
}

.jumbotron {
  background-color: white;
  display:flex;
  /* justify-content: space-between; */
  /* position: absolute; */
}

hr {
  display: block;
  background: black;
  height: 3px;
  width: 300px;
  border: 0;
  border-top: 3px solid black;
  margin: 1em 0;
  padding: 0;
}


.intro-head{
  /* flex-grow: 1; */
  width: 50vw;

}

/* .headliner-cont{ */
  /* background-color: aqua; */
/* } */


/* @media screen and (max-width: 450px)  {
  .intro-head .intro-text{
    flex-grow: 2;
  }

  .intro-head .seth-img{
    flex-grow: 1;
    width: 20px;
    height: 20px;
  }
} */

.intro-text{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.jumbo-text{
  display: flex;
  flex-direction: column;
  color: black;
  /* border: 1px solid black;
  border-radius: 10%; */
  font-size: 7rem;
  padding: 2rem;
}

/* .jumbo-img { */
  /* background-image: url('../../img/seth/seth_left_smile_web2.png'); */
/* } */

.headliner-cont{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: auto;

}

  .headliner-a{
    padding: 3rem;
    text-align: center;
    color: white;
    background-color: rgb(24, 155, 242);
    margin: 1rem;
    border: 1px solid black;
    font-size: 7rem;
    text-transform: uppercase;

  }

  .headliner-b{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    color: black;
    font-size: 17rem;
    /* text-transform: uppercase; */
    /* text-align: center; */
  }

  .headliner-b > * {
    padding: 2rem;
  }

  .big-word{
    background-color: rgb(24, 155, 242);
    padding: 2rem;

  }

  .sub-text{
    background-color: none;
    font-size: 8rem;
    text-transform: lowercase;
  }

  .story-liner{
    display: flex;
    font-size: 7rem;
  }

  .story-liner-text{
    display:flex;
    flex-direction: column;
  }

  .form-group button{
    background-color: darkgrey;
    color: black;
    border-radius: 5%;
  }